import { useEffect, useState } from "react";
import InputQuantityCart from "../Helpers/InputQuantityCart";
import axios from "../../api/axios";
import { toast } from "react-toastify";

export default function ProductsTable({ className, isCart, cartTotal }) {
  const [productList, setProductList] = useState();

  const cartList = (e) => {
    setProductList(e);
    let t = 0;
    e[3].map((k) => {
      t += parseFloat(k);
    });
    cartTotal(t);
  };
  useEffect(() => {
    const itemId = localStorage.getItem("cart_list");
    if (itemId === null) {
      isCart(false);
    } else {
      setProductList(JSON.parse(itemId));
      isCart(true);
    }
  }, []);

  const removeCartItem = (id) => {
    return () => {
      const cart_list = localStorage.getItem("cart_list");
      const cart_list_obj = JSON.parse(cart_list);

      // Find the index of the product using the product ID
      const index = cart_list_obj[0].indexOf(id);

      if (index !== -1) {
        // Remove the product from all arrays
        cart_list_obj[0].splice(index, 1); // Remove product ID
        cart_list_obj[1].splice(index, 1); // Remove quantity
        cart_list_obj[2].splice(index, 1); // Remove other product info (e.g., color, size)
        cart_list_obj[3].splice(index, 1); // Remove subtotal

        // Update local storage with the modified cart
        localStorage.setItem("cart_list", JSON.stringify(cart_list_obj));

        // Update the product list in the component state
        setProductList(cart_list_obj);

        // Recalculate the cart total and update it
        let t = 0;
        cart_list_obj[3].forEach((subTotal) => {
          t += parseFloat(subTotal);
        });
        cartTotal(t);

        // Notify the user of the removal (optional)
        toast.success("Item removed from cart");
      } else {
        toast.error("Item not found in cart");
      }
    };
  };

  return (
    <div className={`w-full ${className || ""}`}>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                product
              </td>
              <td className="py-4 whitespace-nowrap text-center">color</td>
              <td className="py-4 whitespace-nowrap text-center">size</td>
              <td className="py-4 whitespace-nowrap  text-center">quantity</td>
              <td className="py-4 whitespace-nowrap text-center">price</td>
              <td className="py-4 whitespace-nowrap  text-center">subTotal</td>
              <td className="py-4 whitespace-nowrap text-right w-[114px]"></td>
            </tr>
            {/* table heading end */}
            {productList
              ? productList[2].map((pro, index) => (
                  <tr className="bg-white border-b hover:bg-gray-50">
                    <td className="pl-10 py-4 w-[380px]">
                      <div className="flex space-x-6 items-center">
                        <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_FOLDER}/${pro[0]["p_thumbnail"]}`}
                            alt="product"
                            className="w-full h-full object-contain"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.PUBLIC_URL}/assets/images/product-img-1.jpg`;
                            }}
                          />
                        </div>
                        <div className="flex-1 flex flex-col">
                          <p className="font-medium text-[15px] text-qblack">
                            {pro[0]["p_name"]}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-center py-4 px-2">
                      <div className=" flex justify-center items-center">
                        {pro[1]}
                      </div>
                    </td>
                    <td className="text-center py-4 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        {pro[2]}
                      </div>
                    </td>
                    <td className=" py-4">
                      <div className="flex justify-center items-center">
                        <InputQuantityCart
                          newQty={productList[1][index]}
                          id={productList[0][index]}
                          price={parseFloat(pro[3])}
                          cartList={cartList}
                          avaQty={pro[5]}
                        />
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {parseFloat(pro[3]).toFixed(2)}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {(pro[3] * productList[1][index]).toFixed(2)}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <button onClick={removeCartItem(productList[0][index])}>
                          <span>
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                fill="#AAAAAA"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
}
