import { Link } from "react-router-dom";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

export default function CheakoutPage() {
  const navigate = useNavigate();

  const handleCheckoutSuccess = () => {
    const checkoutData = {
      /* your object data */
    };
    navigate("/checkout-success", { state: checkoutData });
  };

  const [productList, setProductList] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    district: "",
    secondaryPhone: "",
    differentAddress: false,
    paymentMethod: "",
    deliveryPhone: "",
    deliveryAddress: "",
    deliveryDistrict: "",
    deliverySecondaryPhone: "",
  });

  const [shippingFee, setShippingFee] = useState(0);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const cartItems = localStorage.getItem("cart_list");
    if (cartItems !== null) {
      console.log("cardItems");
      console.log(cartItems);
      setProductList(JSON.parse(cartItems));
    }
  }, []);

  const handleFormChange = (e, fieldName) => {
    const { value, type, checked } = e.target;

    if (fieldName === "district" && value === "Colombo") {
      setShippingFee(200);
    } else if (fieldName === "district") {
      setShippingFee(300);
    }

    if (formData.differentAddress) {
      if (fieldName === "deliveryDistrict" && value === "Colombo") {
        setShippingFee(200);
      } else if (fieldName === "deliveryDistrict") {
        setShippingFee(300);
      }
    }

    if (fieldName === "differentAddress") {
      if (e.target.checked) {
        if (formData.deliveryDistrict === "Colombo") {
          setShippingFee(200);
        } else {
          setShippingFee(300);
        }
      } else {
        if (formData.district === "Colombo") {
          setShippingFee(200);
        } else {
          setShippingFee(300);
        }
      }
    }

    setFormData({
      ...formData,
      [fieldName]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName?.trim())
      newErrors.firstName = "First name is required";
    if (!formData.lastName?.trim())
      newErrors.lastName = "Last name is required";
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone?.trim()) newErrors.phone = "Phone number is required";
    if (!formData.address?.trim()) newErrors.address = "Address is required";
    if (!formData.district?.trim()) newErrors.district = "District is required";
    if (!formData.paymentMethod)
      newErrors.paymentMethod = "Select a payment method";
    if (formData.differentAddress) {
      if (!formData.deliveryPhone?.trim())
        newErrors.deliveryPhone = "Phone number is required";
      if (!formData.deliveryAddress?.trim())
        newErrors.deliveryAddress = "Address is required";
      if (!formData.deliveryDistrict?.trim())
        newErrors.deliveryDistrict = "District is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePaymentMethod = (e) => {
    setFormData({
      ...formData,
      paymentMethod: e.target.id,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all the required fields");
      return;
    }

    console.log(formData);
    const processedCartData = processCartData(productList);
    console.log(processedCartData);
    const controller = new AbortController();
    const signal = controller.signal;

    const order = async () => {
      try {
        const response = await axios.post(
          "/orders",
          {
            ...formData,
            shippingFee: shippingFee,
            products: processedCartData,
          },
          { signal }
        );
        console.log(response);
        if (response.status === 201) {
          toast.success("Order placed successfully");

          localStorage.removeItem("cart_list");

          const checkoutData = response.data;
          navigate("/checkout-success", { state: checkoutData });
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.error("Request timed out");
          toast.error("Order request timed out. Please try again.");
        } else {
          console.error(error);
          toast.error(
            "An error occurred while placing the order. Please try again."
          );
        }
      }
    };

    order();
  };

  const processCartData = (cartData) => {
    const [identifiers, quantities, products, prices] = cartData;

    return products.map((product, index) => {
      const { p_name, p_brand, p_cat, p_subcat, p_thumbnail } = product[0]; // Extract product details
      const [p_id, c_id, s_id] = identifiers[index].split("_"); // Extract product identifiers

      const color = product[1];
      const size = product[2];
      const productPrice = product[3];
      const sku = product[4];
      const quantity = quantities[index];
      const price = prices[index];

      return {
        product_id: p_id,
        color_id: c_id,
        size_id: s_id,
        product_name: p_name,
        brand: p_brand,
        category_id: p_cat,
        subcategory_id: p_subcat,
        color,
        size,
        price: productPrice,
        sku,
        quantity,
        product_thumbnail: p_thumbnail,
      };
    });
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
        <div className="w-full mb-5">
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "checkout", path: "/checkout" },
            ]}
          />
        </div>
        <div className="checkout-main-content w-full">
          <div className="container-x mx-auto">
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-1/2 w-full">
                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                  Billing Details
                </h1>
                <div className="form-area">
                  <form onSubmit={handleFormSubmit}>
                    <div className="sm:flex sm:space-x-5 items-center mb-6">
                      <div className="sm:w-1/2  mb-5 sm:mb-0">
                        <InputCom
                          label="First Name*"
                          placeholder="First Name"
                          inputClasses="w-full h-[50px]"
                          name="firstName"
                          value={formData.firstName}
                          inputHandler={(e) => handleFormChange(e, "firstName")}
                        />
                        {errors.firstName && (
                          <span className="text-red-500">
                            {errors.firstName}
                          </span>
                        )}
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Last Name*"
                          placeholder="Last Name"
                          inputClasses="w-full h-[50px]"
                          name="lastName"
                          value={formData.lastName}
                          inputHandler={(e) => handleFormChange(e, "lastName")}
                        />
                        {errors.lastName && (
                          <span className="text-red-500">
                            {errors.lastName}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <InputCom
                          label="Email Address*"
                          placeholder="E-mail"
                          inputClasses="w-full h-[50px]"
                          name="email"
                          value={formData.email}
                          inputHandler={(e) => handleFormChange(e, "email")}
                        />
                        {errors.email && (
                          <span className="text-red-500">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="flex-1">
                        <InputCom
                          label="Phone Number*"
                          placeholder="012 3  *******"
                          inputClasses="w-full h-[50px]"
                          name="phone"
                          value={formData.phone}
                          inputHandler={(e) => handleFormChange(e, "phone")}
                        />
                        {errors.phone && (
                          <span className="text-red-500">{errors.phone}</span>
                        )}
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Secondary Phone Number*"
                          placeholder="012 3  *******"
                          inputClasses="w-full h-[50px]"
                          name="secondaryPhone"
                          value={formData.secondaryPhone}
                          inputHandler={(e) =>
                            handleFormChange(e, "secondaryPhone")
                          }
                        />
                      </div>
                    </div>

                    <div className=" mb-6">
                      <div className="w-full">
                        <InputCom
                          label="Address*"
                          placeholder="your address here"
                          inputClasses="w-full h-[50px]"
                          name="address"
                          value={formData.address}
                          inputHandler={(e) => handleFormChange(e, "address")}
                        />
                        {errors.address && (
                          <span className="text-red-500">{errors.address}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <h1 className="input-label capitalize block  mb-2 text-qgray text-[13px] font-normal">
                          District
                        </h1>
                        <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center">
                          <select
                            className="text-[13px] text-qgraytwo"
                            name="district"
                            value={formData.district}
                            onChange={(e) => handleFormChange(e, "district")}
                          >
                            <option value="">Select District</option>
                            <option value="Ampara">Ampara</option>
                            <option value="Anuradhapura">Anuradhapura</option>
                            <option value="Badulla">Badulla</option>
                            <option value="Batticaloa">Batticaloa</option>
                            <option value="Colombo">Colombo</option>
                            <option value="Galle">Galle</option>
                            <option value="Gampaha">Gampaha</option>
                            <option value="Hambantota">Hambantota</option>
                            <option value="Jaffna">Jaffna</option>
                            <option value="Kalutara">Kalutara</option>
                            <option value="Kandy">Kandy</option>
                            <option value="Kegalle">Kegalle</option>
                            <option value="Kilinochchi">Kilinochchi</option>
                            <option value="Kurunegala">Kurunegala</option>
                            <option value="Mannar">Mannar</option>
                            <option value="Matale">Matale</option>
                            <option value="Matara">Matara</option>
                            <option value="Monaragala">Monaragala</option>
                            <option value="Mullaitivu">Mullaitivu</option>
                            <option value="Nuwara Eliya">Nuwara Eliya</option>
                            <option value="Polonnaruwa">Polonnaruwa</option>
                            <option value="Puttalam">Puttalam</option>
                            <option value="Ratnapura">Ratnapura</option>
                            <option value="Trincomalee">Trincomalee</option>
                            <option value="Vavuniya">Vavuniya</option>
                          </select>
                          {errors.district && (
                            <span className="text-red-500">
                              {errors.district}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex space-x-2 items-center mb-10">
                      <div>
                        <input type="checkbox" name="" id="create" />
                      </div>
                      <label
                        htmlFor="create"
                        className="text-qblack text-[15px] select-none"
                      >
                        Create an account?
                      </label>
                    </div> */}
                    <div>
                      <h1 className="text-2xl text-qblack font-medium mb-3">
                        Delivery Address
                      </h1>
                      <div className="flex space-x-2 items-center mb-10">
                        <div>
                          <input
                            type="checkbox"
                            name="differentAddress"
                            id="address"
                            onChange={(e) =>
                              handleFormChange(e, "differentAddress")
                            }
                          />
                        </div>
                        <label
                          htmlFor="address"
                          className="text-qblack text-[15px] select-none"
                        >
                          Ship to a different address
                        </label>
                      </div>
                    </div>
                    {formData.differentAddress && (
                      <div>
                        <div className="flex space-x-5 items-center mb-6">
                          <div className="flex-1">
                            <InputCom
                              label="Phone Number*"
                              placeholder="012 3  *******"
                              inputClasses="w-full h-[50px]"
                              name="deliveryPhone"
                              value={formData.deliveryPhone}
                              inputHandler={(e) =>
                                handleFormChange(e, "deliveryPhone")
                              }
                            />
                            {errors.deliveryPhone && (
                              <span className="text-red-500">
                                {errors.deliveryPhone}
                              </span>
                            )}
                          </div>
                          <div className="flex-1">
                            <InputCom
                              label="Secondary Phone Number*"
                              placeholder="012 3  *******"
                              inputClasses="w-full h-[50px]"
                              name="deliverySecondaryPhone"
                              value={formData.deliverySecondaryPhone}
                              inputHandler={(e) =>
                                handleFormChange(e, "deliverySecondaryPhone")
                              }
                            />
                          </div>
                        </div>
                        <div className=" mb-6">
                          <div className="w-full">
                            <InputCom
                              label="Address*"
                              placeholder="your address here"
                              inputClasses="w-full h-[50px]"
                              name="deliveryAddress"
                              value={formData.deliveryAddress}
                              inputHandler={(e) =>
                                handleFormChange(e, "deliveryAddress")
                              }
                            />
                            {errors.deliveryAddress && (
                              <span className="text-red-500">
                                {errors.deliveryAddress}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex space-x-5 items-center mb-6">
                          <div className="w-1/2">
                            <h1 className="input-label capitalize block  mb-2 text-qgray text-[13px] font-normal">
                              District
                            </h1>
                            <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center">
                              <select
                                className="text-[13px] text-qgraytwo"
                                name="deliveryDistrict"
                                value={formData.deliveryDistrict}
                                onChange={(e) =>
                                  handleFormChange(e, "deliveryDistrict")
                                }
                              >
                                <option value="">Select District</option>
                                <option value="Ampara">Ampara</option>
                                <option value="Anuradhapura">
                                  Anuradhapura
                                </option>
                                <option value="Badulla">Badulla</option>
                                <option value="Batticaloa">Batticaloa</option>
                                <option value="Colombo">Colombo</option>
                                <option value="Galle">Galle</option>
                                <option value="Gampaha">Gampaha</option>
                                <option value="Hambantota">Hambantota</option>
                                <option value="Jaffna">Jaffna</option>
                                <option value="Kalutara">Kalutara</option>
                                <option value="Kandy">Kandy</option>
                                <option value="Kegalle">Kegalle</option>
                                <option value="Kilinochchi">Kilinochchi</option>
                                <option value="Kurunegala">Kurunegala</option>
                                <option value="Mannar">Mannar</option>
                                <option value="Matale">Matale</option>
                                <option value="Matara">Matara</option>
                                <option value="Monaragala">Monaragala</option>
                                <option value="Mullaitivu">Mullaitivu</option>
                                <option value="Nuwara Eliya">
                                  Nuwara Eliya
                                </option>
                                <option value="Polonnaruwa">Polonnaruwa</option>
                                <option value="Puttalam">Puttalam</option>
                                <option value="Ratnapura">Ratnapura</option>
                                <option value="Trincomalee">Trincomalee</option>
                                <option value="Vavuniya">Vavuniya</option>
                              </select>
                              {errors.deliveryDistrict && (
                                <span className="text-red-500">
                                  {errors.deliveryDistrict}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="flex-1">
                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                  Order Summary
                </h1>

                <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                  <div className="sub-total mb-6">
                    <div className=" flex justify-between mb-5">
                      <p className="text-[13px] font-medium text-qblack uppercase">
                        Product
                      </p>
                      <p className="text-[13px] font-medium text-qblack uppercase">
                        total
                      </p>
                    </div>
                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                  </div>
                  <div className="product-list w-full mb-[30px]">
                    <ul className="flex flex-col space-y-5">
                      {productList.length > 2 &&
                        productList[2].map((product, index) => (
                          <li key={index}>
                            <div className="flex justify-between items-center">
                              <div>
                                <h4 className="text-[15px] text-qblack mb-2.5">
                                  {product[0].p_name}
                                  <sup className="text-[13px] text-qgray ml-2 mt-2">
                                    x{productList[1][index]}
                                  </sup>
                                </h4>
                                <p className="text-[13px] text-qgray">
                                  {product[1]}{" "}
                                  {product[2] ? `, ${product[2]}` : ""}
                                  {product[4] ? `, ${product[4]}` : ""}
                                </p>
                              </div>
                              <div>
                                <span className="text-[15px] text-qblack font-medium">
                                  {productList[3][index]} LKR
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                  <div className="mt-[30px]">
                    <div className=" flex justify-between mb-5">
                      <p className="text-[13px] font-medium text-qblack uppercase">
                        SUBTOTAL
                      </p>
                      <p className="text-[15px] font-medium text-qblack uppercase">
                        {productList.length > 3
                          ? `${productList[3].reduce((acc, price) => acc + parseFloat(price), 0)} LKR`
                          : "0 LKR"}
                      </p>
                    </div>
                  </div>

                  <div className="w-full mt-[30px]">
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-5">
                        <div>
                          <span className="text-xs text-qgraytwo mb-3 block">
                            SHIPPING
                          </span>
                          {/* <p className="text-base font-medium text-qblack">
                            Free Shipping
                          </p> */}
                        </div>
                        <p className="text-[15px] font-medium text-qblack">
                          {shippingFee} LKR
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                  </div>

                  <div className="mt-[30px]">
                    <div className=" flex justify-between mb-5">
                      <p className="text-2xl font-medium text-qblack">Total</p>
                      <p className="text-2xl font-medium text-qred">
                        {productList.length > 3
                          ? `${productList[3].reduce((acc, price) => acc + parseFloat(price), 0) + shippingFee} LKR`
                          : `${shippingFee} LKR`}
                      </p>
                    </div>
                  </div>
                  <div className="shipping mt-[30px]">
                    <ul className="flex flex-col space-y-1">
                      {/* <li className=" mb-5">
                        <div className="flex space-x-2.5 items-center mb-4">
                          <div className="input-radio">
                            <input
                              type="radio"
                              name="price"
                              className="accent-pink-500"
                              id="transfer"
                              onChange={handlePaymentMethod}
                            />
                          </div>
                          <label
                            htmlFor="transfer"
                            className="text-[18px] text-normal text-qblack"
                          >
                            Direct Bank Transfer
                          </label>
                        </div>
                        <p className="text-qgraytwo text-[15px] ml-6">
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                        </p>
                      </li> */}
                      <li>
                        <div className="flex space-x-2.5 items-center mb-5">
                          <div className="input-radio">
                            <input
                              type="radio"
                              name="price"
                              className="accent-pink-500"
                              id="COD"
                              onChange={handlePaymentMethod}
                            />
                          </div>
                          <label
                            htmlFor="COD"
                            className="text-[18px] text-normal text-qblack"
                          >
                            Cash on Delivery
                          </label>
                        </div>
                      </li>
                      {/* <li>
                        <div className="flex space-x-2.5 items-center mb-5">
                          <div className="input-radio">
                            <input
                              type="radio"
                              name="price"
                              className="accent-pink-500"
                              id="bank"
                              onChange={handlePaymentMethod}
                            />
                          </div>
                          <label
                            htmlFor="bank"
                            className="text-[18px] text-normal text-qblack"
                          >
                            Credit/Debit Cards or Paypal
                          </label>
                        </div>
                      </li> */}
                    </ul>
                    {errors.paymentMethod && (
                      <span className="text-red-500">
                        {errors.paymentMethod}
                      </span>
                    )}
                  </div>
                  <button onClick={handleFormSubmit}>
                    <div className="w-full h-[50px] black-btn flex justify-center items-center">
                      <span className="text-sm font-semibold">
                        Place Order Now
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
