import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Checkbox from "../Helpers/Checkbox";
import { useEffect, useState } from "react";
import axios, { all } from "axios";
import { json } from "react-router-dom";

export default function ProductsFilter({
  filters,
  updateFilters,
  priceRange,
  priceRangeHandler,
  storage,
  filterstorage,
  className,
  filterToggle,
  filterToggleHandler,
}) {
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [brandList, setBrandList] = useState([]);

  const hasActiveFilters = Object.values(filters).some(
    (value) => value === true
  );

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const brand = async () => {
      return await axios.get(
        `${process.env.REACT_APP_REDIRECT_URI}/product/getBrandList`
      );
    };
    brand()
      .then((response) => {
        if (isMounted) setBrandList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    const category = async () => {
      return await axios.get(
        `${process.env.REACT_APP_REDIRECT_URI}/category/getFullCategoryList`
      );
    };
    category()
      .then((response) => {
        if (isMounted) setProductCategoryList(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isMounted = false;
        controller.abort();
      });
  }, []);

  const toggleSubcategories = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const checkboxHandler = (e, id = null, isCategory = false) => {
    const { name, checked } = e.target;

    updateFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: checked };

      if (isCategory) {
        const category = productCategoryList.find((cat) => cat.cat_id === id);
        if (category && category.subcategories) {
          category.subcategories.forEach((subcat) => {
            updatedFilters[`category-${id}-subcategory-${subcat.sub_cat_id}`] =
              checked;
          });
        }
      } else if (name.includes("subcategory")) {
        const [mainCategory, mainCategoryId] = name.split("-");

        if (checked) {
          updatedFilters[`category-${mainCategoryId}`] = true;
        } else {
          const subcategories = productCategoryList.find(
            (cat) => cat.cat_id === mainCategoryId
          ).subcategories;

          const allUnchecked = subcategories.every((subcat) => {
            return !updatedFilters[
              `category-${mainCategoryId}-subcategory-${subcat.sub_cat_id}`
            ];
          });

          if (allUnchecked) {
            updatedFilters[`category-${mainCategoryId}`] = false;
          }
        }
      }

      return updatedFilters;
    });
  };

  const clearFilters = () => {
    updateFilters({});
  };

  return (
    <>
      <div
        className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px] ${
          className || ""
        }  ${filterToggle ? "block" : "hidden lg:block"}`}
      >
        {/* Added Filters */}
        {hasActiveFilters && (
          <div className="filter-subject-item pb-10 border-b border-qgray-border">
            <div className="subject-title mb-[30px] flex items-center justify-between">
              <h1 className="text-black text-base font-500">Added Filters</h1>
              <button
                onClick={clearFilters}
                className="text-xs font-500 underline"
              >
                Clear All
              </button>
            </div>
            <div className="flex space-x-[5px] flex-wrap">
              {Object.entries(filters).map(([key, value]) => {
                let keyName = "";

                if (key.includes("category")) {
                  const catId = key.split("-")[1];
                  console.log("catId", catId);
                  if (key.includes("subcategory")) {
                    const subcatId = key.split("-")[3];

                    console.log("productCatList", productCategoryList);
                    const category = productCategoryList.find(
                      (cat) => cat.cat_id === catId
                    );
                    console.log("category found", category);
                    const subcategory = category.subcategories.find(
                      (subcat) => subcat.sub_cat_id === subcatId
                    );
                    console.log("subcategory found", subcategory);
                    keyName = subcategory.product_sub_cat_name;
                  } else {
                    console.log("productCatList2", productCategoryList);

                    const category = productCategoryList.find(
                      (cat) => cat.cat_id === catId
                    );
                    console.log("category found", category);
                    keyName = category.product_cat_name;
                  }
                }

                if (key.includes("brand")) {
                  const brand = brandList.find(
                    (brand) => brand.p_brand === key.split("-")[1]
                  );
                  keyName = brand.p_brand;
                }

                return (
                  value && (
                    <span
                      key={key}
                      className="font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] flex items-center justify-between"
                    >
                      {keyName}
                      <button
                        onClick={() => {
                          checkboxHandler(
                            { target: { name: key, checked: false } },
                            key.includes("category")
                              ? parseInt(key.split("-")[1])
                              : null,
                            key.includes("category") &&
                              !key.includes("subcategory")
                          );
                        }}
                        className="ml-2 font-500"
                      >
                        x
                      </button>
                    </span>
                  )
                );
              })}
            </div>
          </div>
        )}

        {/* Product Categories */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">
              Product Categories
            </h1>
          </div>
          <div className="filter-items">
            <ul>
              {productCategoryList.map((category) => (
                <li key={category.cat_id} className="item mb-5">
                  <div className="flex justify-between items-center">
                    <div className="flex  space-x-[14px] items-center">
                      <Checkbox
                        id={`category-${category.cat_id}`}
                        name={`category-${category.cat_id}`}
                        handleChange={(e) =>
                          checkboxHandler(e, category.cat_id, true)
                        }
                        checked={!!filters[`category-${category.cat_id}`]} // Use !! to ensure a boolean value
                      />
                      <label
                        htmlFor={`category-${category.cat_id}`}
                        className="text-xs font-black font-400 capitalize"
                      >
                        {category.product_cat_name}
                      </label>
                    </div>
                    <button
                      onClick={() => toggleSubcategories(category.cat_id)}
                      className="text-xs font-400 capitalize text-qgray"
                    >
                      {expandedCategories[category.cat_id] ? "-" : "+"}
                    </button>
                  </div>
                  {expandedCategories[category.cat_id] && (
                    <ul className="pl-6 mt-2">
                      {category.subcategories.map((subcat) => (
                        <li key={subcat.sub_cat_id} className="item mb-3">
                          <div className="flex space-x-[10px] items-center">
                            <Checkbox
                              id={`subcategory-${subcat.sub_cat_id}`}
                              name={`category-${category.cat_id}-subcategory-${subcat.sub_cat_id}`}
                              handleChange={(e) =>
                                checkboxHandler(e, subcat.sub_cat_id)
                              }
                              checked={
                                !!filters[
                                  `category-${category.cat_id}-subcategory-${subcat.sub_cat_id}`
                                ]
                              } // Use !! to ensure a boolean value
                            />
                            <label
                              htmlFor={`subcategory-${subcat.sub_cat_id}`}
                              className="text-xs font-400 capitalize"
                            >
                              {subcat.product_sub_cat_name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Price Range */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Price Range</h1>
          </div>
          <div className="price-range mb-5">
            <InputRange
              draggableTrack
              maxValue={50000}
              minValue={0}
              value={priceRange}
              onChange={priceRangeHandler}
              step={100}
            />
          </div>
          <p className="text-xs text-qblack font-400">
            Price: {priceRange.min} LKR - {priceRange.max} LKR
          </p>
        </div>

        {/* Brands */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Brands</h1>
          </div>
          <div className="filter-items">
            <ul>
              {brandList.map((brands, index) => (
                <li
                  key={index}
                  className="item flex justify-between items-center mb-5"
                >
                  <div className="flex space-x-[14px] items-center">
                    <Checkbox
                      id={`brand-${index}`}
                      name={`brand-${brands.p_brand}`}
                      handleChange={(e) => checkboxHandler(e, brands.p_brand)}
                      checked={!!filters[`brand-${brands.p_brand}`]}
                    />
                    <label
                      htmlFor={`brand-${brands.p_brand}`}
                      className="text-xs font-black font-400 capitalize"
                    >
                      {brands.p_brand}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Storage */}
        {/* <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Storage</h1>
          </div>
          <div className="filter-items">
            <div className="flex space-x-[5px] flex-wrap">
              <span
                onClick={() => filterstorage("64GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "64GB"
                    ? "theme-main-bg text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                64GB
              </span>
              <span
                onClick={() => filterstorage("128GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "128GB"
                    ? "theme-main-bg text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                128GB
              </span>
              <span
                onClick={() => filterstorage("256GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "256GB"
                    ? "theme-main-bg text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                256GB
              </span>
              <span
                onClick={() => filterstorage("512GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "512GB"
                    ? "theme-main-bg text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                512GB
              </span>
              <span
                onClick={() => filterstorage("1024GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "1024GB"
                    ? "theme-main-bg text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                1024GB
              </span>
            </div>
          </div>
        </div> */}

        {/* Sizes (if needed) */}
        {/* <div className="filter-subject-item pb-10 mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Sizes</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeS"
                      name="sizeS"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeS}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeS"
                      className="text-xs font-black font-400 capitalize"
                    >
                      s
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeM"
                      name="sizeM"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeM}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeM"
                      className="text-xs font-black font-400 capitalize"
                    >
                      m
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeL"
                      name="sizeL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      l
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeXL"
                      name="sizeXL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeXL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeXL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      xl
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div
        onClick={filterToggleHandler}
        className={`offcanvas-overlay fixed inset-0 bg-black bg-opacity-50 z-50 cursor-pointer ${
          filterToggle ? "block lg:hidden" : "hidden"
        }`}
      />
    </>
  );
}
