import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../../api/axios";
import ProductCardRowSearch from "../../Helpers/Cards/ProductCardRowSearch";
import { Link } from "react-router-dom";

export default function SearchBox({ className, type }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const searchBoxRef = useRef(null);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post("/productSearch", { searchTerm });
      setResults(response.data.slice(0, 5)); // Show only 5 suggestions
    } catch (error) {
      console.error("Error searching for products:", error);
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
    setResults([]);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), [
    searchTerm,
  ]);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch();
    } else {
      setResults([]);
    }
  }, [searchTerm, debouncedSearch]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       searchBoxRef.current &&
  //       !searchBoxRef.current.contains(event.target)
  //     ) {
  //       setResults([]);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <>
      <div
        ref={searchBoxRef}
        className={`w-full h-full flex items-center border border-qgray-border bg-white ${
          className || ""
        }`}
      >
        <div className="flex-1 bg-red-500 h-full relative">
          <form onSubmit={(e) => e.preventDefault()} className="h-full">
            <input
              type="text"
              className="search-input"
              placeholder="Search Product..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={clearSearch}
              >
                ✕
              </button>
            )}
          </form>
        </div>
        <button
          className={`w-[93px] h-full text-sm font-600 ${
            type === 3 ? "bg-qh3-blue text-white" : "search-btn"
          }`}
          type="submit"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      {results.length > 0 && (
        <div
          className="search-results"
          style={{ position: "absolute", zIndex: 9999 }}
        >
          {results.map((result) => (
            <Link to={`/single-product/${result.id}`} onClick={clearSearch}>
              <ProductCardRowSearch key={result.id} datas={result} />
            </Link>
          ))}
        </div>
      )}
    </>
  );
}
