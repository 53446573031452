import { Link } from "react-router-dom";
import { useEffect } from "react";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="page-title mb-10">
        <PageTitle
          title="Contact"
          breadcrumb={[
            { name: "home", path: "/" },
            { name: "contact", path: "/contact" },
          ]}
        />
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="lg:w-1/2 w-full">
              <h1 className="text-[22px] font-semibold text-qblack leading-[30px] mb-1">
                Contact Information
              </h1>
              <p className="text-[15px] text-qgraytwo leading-[30px] mb-5">
                Fill the form below or write us .We will help you as soon as
                possible.
              </p>

              <div className="xl:flex xl:space-x-[30px] mb-[30px]">
                <div className="xl:w-1/2 w-full  h-[196px] flex flex-col item justify-center bg-[#FFEAE5] p-5">
                  <div className="flex justify-center mb-3 ">
                    <div className="flex justify-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/phone.png`}
                        className="w-[50px] h-[50px]"
                        alt="Phone"
                      />
                    </div>
                  </div>
                  <p className="text-[22px] text-black leading-[30px] text-center font-semibold">
                    Phone
                  </p>
                  {/* <p className="text-[15px] text-black leading-[30px] text-center">
                    +(94) 11 2 xxx xxx
                  </p> */}
                  <p className="text-[15px] text-black leading-[30px] text-center">
                    +(94) 77 714 4288
                  </p>
                </div>
                <div className="xl:w-1/2 w-full h-[196px] flex flex-col item justify-center bg-[#D3EFFF] p-5">
                  <div className="flex justify-center mb-3 ">
                    <div className="flex justify-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/email.png`}
                        className="w-[50px] h-[50px]"
                        alt="Email"
                      />
                    </div>
                  </div>
                  <p className="text-[22px] text-black leading-[30px] text-center font-semibold">
                    Email
                  </p>
                  <p className="text-[15px] text-black leading-[30px] text-center">
                    info@items.lk
                  </p>
                  {/* <p className="text-[15px] text-black leading-[30px] text-center">
                    support.itemslk@gmail.com
                  </p> */}
                </div>
              </div>
              <div className="p-5 flex flex-col justify-between w-full bg-[#E7F2EC]">
                <div className="flex space-x-5">
                  <div className="flex justify-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/address.png`}
                      className="w-[50px] h-[50px]"
                      alt="Address"
                    />
                  </div>
                  <div>
                    <h1 className="text-[22px] font-semibold text-qblack leading-[30px] mb-2">
                      Address
                    </h1>
                    <p className="text-[15px] text-qblack leading-[30px]">
                      No 158, Kawdana Road, Dehiwala,
                      <br />
                      Sri Lanka.
                    </p>
                  </div>
                </div>
                <div className="w-full h-[206px] mt-5">
                  <iframe
                    title="items.lk"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.386208445641!2d79.8753476!3d6.844218599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25ae290d5aa1b%3A0x4ec2d8523d67215d!2s158%20Kawdana%20Rd%2C%20Dehiwala-Mount%20Lavinia%2010350!5e0!3m2!1sen!2slk!4v1735293354996!5m2!1sen!2slk"
                    style={{ border: "0", width: "100%", height: "100%" }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="flex-1 bg-white sm:p-10 p-3">
              <div className="title flex flex-col items-center">
                <h1 className="text-[34px] font-bold text-qblack">
                  Get In Touch
                </h1>
                {/* <span className="-mt-5 block">
                  <svg
                    width="354"
                    height="30"
                    viewBox="0 0 354 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                      stroke="#FFBB38"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span> */}
              </div>
              <div className="inputs mt-5">
                <div className="mb-4">
                  <InputCom
                    label="Frist Name*"
                    placeholder="Demo Name"
                    name="first_name"
                    inputClasses="h-[50px]"
                  />
                </div>
                <div className="mb-4">
                  <InputCom
                    label="Email Address*"
                    placeholder="abc@gmail.com"
                    name="email"
                    inputClasses="h-[50px]"
                  />
                </div>
                <div className="mb-4">
                  <InputCom
                    label="Subject*"
                    placeholder="Your Subject here"
                    name="subject"
                    inputClasses="h-[50px]"
                  />
                </div>
                <div className="mb-5">
                  <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                    Message*
                  </h6>
                  <textarea
                    placeholder="Type your message here"
                    className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                  ></textarea>
                </div>
                <div>
                  <button>
                    <div className="black-btn text-sm font-semibold w-full h-[50px] flex justify-center items-center">
                      <span>Send Now</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
