import { Link } from "react-router-dom";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import UseCartHook from "../../../../hooks/useCartHook";
import { useEffect } from "react";
import SearchBox from "../../../Helpers/SearchBox";

export default function HeaderOne({ className, drawerAction, type = 1 }) {
  const { cartCount, setCartCount } = UseCartHook();

  useEffect(() => {
    const cartClickCount = localStorage.getItem("cart_list");
    if (cartClickCount !== null) {
      setCartCount(JSON.parse(cartClickCount)[0].length);
    } else {
      setCartCount(0);
    }
  }, [setCartCount]);

  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      <TopBar className="quomodo-shop-top-bar" />
      <Middlebar
        type={type}
        className="quomodo-shop-middle-bar lg:block hidden"
      />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            {type === 3 ? (
              <Link to="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-3.svg`}
                  alt="logo"
                />
              </Link>
            ) : type === 4 ? (
              <Link to="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-4.svg`}
                  alt="logo"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="logo"
                />
              </Link>
            )}
          </div>
          <div className="cart relative cursor-pointer">
            <Link to="/cart">
              <span>
                <ThinBag />
              </span>
              <span
                className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? "bg-qh3-blue text-white" : "theme-main-bg text-qblack"}`}
              >
                {cartCount}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-[44px] mt-2 lg:hidden">
        <SearchBox type={type} className="search-com" />
      </div>
      <Navbar type={type} className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}
