import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function InputQuantityCart(props) {
  let [qty, setQty] = useState(1);

  const cart_list = localStorage.getItem("cart_list");
  const cart_list_obj = JSON.parse(cart_list);
  const index = cart_list_obj[0].indexOf(props.id);
  useEffect(() => {
    props.cartList(cart_list_obj);
  }, []);

  const increment = () => {
    if (cart_list_obj[1][index] < props.avaQty) {
      setQty((qty += 1));
      cart_list_obj[1][index] = cart_list_obj[1][index] + 1;
      let subT = (props.price * cart_list_obj[1][index]).toFixed(2);
      cart_list_obj[3][index] = subT;
      localStorage.setItem("cart_list", JSON.stringify(cart_list_obj));
      props.cartList(cart_list_obj);
    }
  };

  const decrement = () => {
    if (cart_list_obj[1][index] > 1) {
      setQty((qty -= 1));
      cart_list_obj[1][index] = cart_list_obj[1][index] - 1;
      let subT = (props.price * cart_list_obj[1][index]).toFixed(2);
      cart_list_obj[3][index] = subT;
      localStorage.setItem("cart_list", JSON.stringify(cart_list_obj));
      props.cartList(cart_list_obj);
    }
  };

  return (
    <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray"
        >
          -
        </button>
        <span className="text-qblack">{cart_list_obj[1][index]}</span>
        <button
          onClick={increment}
          type="button"
          className="text-base text-qgray"
        >
          +
        </button>
      </div>
    </div>
  );
}
