import { useEffect, useState } from "react";
// import "react-input-range/lib/css/index.css";
import productDatas from "../../data/products.json";
import BreadcrumbCom from "../BreadcrumbCom";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";
import axios from "../../api/axios";
import Pagination from "../Helpers/Pagination";

export default function AllProductPage() {
  const [filters, setFilter] = useState({});

  const checkboxHandler = (e) => {
    const { name } = e.target;
    setFilter((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const [priceRange, setPriceRange] = useState({ min: 0, max: 50000 });

  const [sortValue, setSortValue] = useState("default");

  const breadcrumbPaths = [
    { name: "home", path: "/" },
    { name: "Shop All", path: "/all-products" },
  ];

  const sortHandler = (e) => {
    setSortValue(e.target.value);
  };

  const [storage, setStorage] = useState(null);
  const filterStorage = (value) => {
    setStorage(value);
  };
  const [filterToggle, setToggle] = useState(false);

  const { products } = productDatas;

  const [productList, setProductList] = useState([]);

  const [productListLength, setProductListLength] = useState(0);

  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 12;

  const paginationStart = (pageNumber - 1) * itemsPerPage;
  const paginationEnd = Math.min(
    paginationStart + itemsPerPage,
    productList.length
  );

  const changePageNumber = (number) => {
    setPageNumber(number);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateFilters = (filters) => {
    setFilter(filters);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const fetchFilteredProducts = async () => {
      try {
        const response = await axios.post("/product/getFilteredProducts", {
          filters: filters,
          priceRange: priceRange,
          sort: sortValue,
        });
        if (isMounted) {
          setProductList(response.data);
          setProductListLength(response.data.length);
        }
        console.log("All products", response.data);
      } catch (error) {
        console.error(error);
      } finally {
        isMounted = false;
        controller.abort();
      }
    };

    fetchFilteredProducts();

    //reset page number to 1
    setPageNumber(1);

    // Cleanup function
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [filters, priceRange, sortValue]); // <- Include filters in the dependency array

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const product = async () => {
      return await axios.get("/getProductList");
    };
    product()
      .then((response) => {
        isMounted && setProductList(response.data);
        isMounted && setProductListLength(response.data.length);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isMounted = false;
        controller.abort();
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  }, []);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom paths={breadcrumbPaths} />
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-[270px]">
                <ProductsFilter
                  updateFilters={updateFilters}
                  filterToggle={filterToggle}
                  filterToggleHandler={() => setToggle(!filterToggle)}
                  filters={filters}
                  checkboxHandler={checkboxHandler}
                  priceRange={priceRange}
                  priceRangeHandler={(value) => setPriceRange(value)}
                  storage={storage}
                  filterstorage={filterStorage}
                  className="mb-[30px]"
                />

                <div className="w-full hidden lg:block h-[295px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
                    alt=""
                    className="w-full h-full object-contain" //h-[300px]
                  />
                </div>
              </div>

              <div className="flex-1">
                <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                  <div>
                    <p className="font-400 text-[13px]">
                      <span className="text-qgray"> Showing</span>{" "}
                      {paginationStart + 1} {" - "} {paginationEnd} {" of "}
                      {productListLength} results
                    </p>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <span className="font-400 text-[13px]">Sort by:</span>
                    <div className="flex space-x-3 items-center border-b border-b-qgray">
                      <select
                        className="font-400 text-[13px] text-qgray"
                        value={sortValue}
                        onChange={sortHandler}
                      >
                        <option value="default">Default</option>
                        <option value="price-low-high">
                          Price, Low to High
                        </option>
                        <option value="price-high-low">
                          Price, High to Low
                        </option>
                        <option value="new-arrival">Newest Arrivals</option>
                        <option value="a-z">Alphabetically, A-Z</option>
                        <option value="z-a">Alphabetically, Z-A</option>
                      </select>
                    </div>
                  </div>
                  <button
                    onClick={() => setToggle(!filterToggle)}
                    type="button"
                    className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-theme text-theme"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
                  <DataIteration
                    datas={productList}
                    startLength={paginationStart}
                    endLength={paginationEnd}
                  >
                    {({ datas }) => (
                      <div data-aos="fade-up" key={datas.id}>
                        <ProductCardStyleOne datas={datas} />
                      </div>
                    )}
                  </DataIteration>
                </div>

                {/* <div className="w-full h-[164px] overflow-hidden mb-[40px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]">
                  <DataIteration
                    datas={productList}
                    startLength={paginationStart + 5}
                    endLength={paginationEnd}
                  >
                    {({ datas }) => (
                      <div data-aos="fade-up" key={datas.id}>
                        <ProductCardStyleOne datas={datas} />
                      </div>
                    )}
                  </DataIteration>
                </div> */}
                <div className="products-sorting w-full bg-white md:h-[70px] flex flex-col justify-center items-center p-[30px] mb-[40px]">
                  <Pagination
                    totalLength={productList.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={pageNumber}
                    changePageNumber={changePageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
