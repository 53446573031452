import { Link } from "react-router-dom";

export default function BrandSection({ className, sectionTitle }) {
  return (
    <div data-aos="fade-up" className={`w-full ${className || ""}`}>
      <div className="container-x mx-auto">
        <div className=" section-title flex justify-between items-center mb-5">
          <div>
            <h1 className="sm:text-3xl text-xl font-600 text-qblacktext">
              {sectionTitle}
            </h1>
          </div>
        </div>
        <div className="grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-2">
          <div className="item">
            <Link to="/brand/Baseus">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-1.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Remax">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-2.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Aspor">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-3.jpeg`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Anker">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-4.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Xiaomi">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-5.jpg`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Ldnio">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-6.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/UAG">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-7.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/WiWu">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-8.png`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/GreenLion">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-9.png`}
                  alt="logo"
                  className="w-[50%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Lito">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-10.jpeg`}
                  alt="logo"
                  className="w-[60%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/UGreen">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-11.webp`}
                  alt="logo"
                  className="w-[70%]"
                />
              </div>
            </Link>
          </div>
          <div className="item">
            <Link to="/brand/Yesplus">
              <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/brand-12.png`}
                  alt="logo"
                  className="w-[80%]"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
