import React, { useEffect, useRef, useState } from "react";
import Star from "../Helpers/icons/Star";
import Selectbox from "../Helpers/Selectbox";
import UseCartHook from "../../hooks/useCartHook";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { format } from "prettier";
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
});
export default function ProductView({ className, reportHandler, pro }) {
  const details = {
    product: "",
    color: "",
    image: "",
    size: "",
  };

  const [product, setProduct] = useState(details);
  const [sizes, setSizes] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [price, setPrice] = useState(false);
  const [priceInternal, setPriceInternal] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [initialOldPrice, setInitialOldPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(false);
  const [avaQty, setAvaQty] = useState(0);
  const [newAvailQty, setNewAvailQty] = useState(100000);
  const [productOutOfStock, setProductOutOfStock] = useState(false);
  const [size, setSize] = useState({ id: 0, size: "" });
  const [color, setColor] = useState({ id: 0, color: "" });
  const [src, setSrc] = useState();
  const [hasSpecialPrice, setHasSpecialPrice] = useState(false);
  const [initialPrice, setInitialPrice] = useState(0);
  const [selectedSKU, setSelectedSKU] = useState("");
  const [variantType, setVariantType] = useState("Variant");

  useEffect(() => {
    if (Object.values(pro).length > 0) {
      setProduct({
        product: pro[0][0],
        color: pro[1],
        image: pro[2],
        size: pro[3],
        priceR: pro[4][0],
      });
      const product = pro[4][0];
      const currentDate = new Date();
      const minDateStart = new Date(product.min_date_start);
      const maxDateEnd = new Date(product.max_date_end);

      const outOfStock = areAllSizesOutOfStock(pro[3]);

      setProductOutOfStock(outOfStock);

      const hasSpecialPrice =
        product.minsp !== null &&
        product.maxsp !== null &&
        product.minsp !== product.minp &&
        product.maxsp !== product.maxp &&
        minDateStart !== null &&
        maxDateEnd !== null &&
        currentDate >= minDateStart &&
        currentDate <= maxDateEnd;

      setHasSpecialPrice(hasSpecialPrice);

      if (hasSpecialPrice) {
        if (product.minsp === product.maxsp) {
          const price = product.minsp;
          setPrice(formatter.format(price));
          setPriceInternal(price);
          setOldPrice(formatter.format(product.maxp));
          setInitialOldPrice(formatter.format(product.maxp));
          const discount = ((product.maxp - price) / product.maxp) * 100;
          setDiscountPercent(Math.round(discount));
          setInitialPrice(formatter.format(price));
        } else {
          const minPrice = product.minsp;
          const maxPrice = product.maxsp;
          const price =
            formatter.format(minPrice) + " - " + formatter.format(maxPrice);
          setPrice(price);
          setOldPrice(
            formatter.format(product.minp) +
              " - " +
              formatter.format(product.maxp)
          );
          setInitialOldPrice(
            formatter.format(product.minp) +
              " - " +
              formatter.format(product.maxp)
          );
          const discount = ((product.maxp - minPrice) / product.maxp) * 100;
          setDiscountPercent(Math.round(discount));
          setInitialPrice(price);
        }
      } else {
        if (product.maxp === product.minp) {
          const price = product.maxp;
          setPrice(formatter.format(price));
          setPriceInternal(price);
          setInitialPrice(formatter.format(price));
        } else {
          const minPrice = product.minp;
          const maxPrice = product.maxp;
          const price =
            formatter.format(minPrice) + " - " + formatter.format(maxPrice);
          setPrice(price);
          setInitialPrice(price);
        }
      }
      setSrc(
        process.env.REACT_APP_IMAGE_FOLDER + `/` + pro[2][0][1][0]["name"]
      );
    }
  }, []);

  const productsImg = [
    {
      id: 1,
      src: "product-gold.png",
      color: "#FFD700",
    },
    {
      id: 1,
      src: "product-gold.png",
      color: "#FFD700",
    },
    {
      id: 1,
      src: "product-gold.png",
      color: "#FFD700",
    },
    {
      id: 2,
      src: "product-black.png",
      color: "#000000",
    },
    {
      id: 3,
      src: "product-details-3.png",
      color: "#FFFFFF",
    },
    {
      id: 4,
      src: "product-details-4.png",
      color: "#FF7173",
    },
    {
      id: 6,
      src: "product-details-5.png",
      color: "",
    },
  ];

  const { setCartCount } = UseCartHook();

  const changeImgHandler = (currentColor) => {
    //if value is "" then set price as initial price
    if (currentColor.target.value === "") {
      setPrice(initialPrice);
      setPriceInternal(initialPrice);
      setOldPrice(initialOldPrice);
      setAvaQty(0);
      setNewAvailQty(100000);
      setSizeOptions([]); // Reset size options if no color is selected
      setSizes(false); // Reset sizes availability
      return;
    }

    const [id, c] = currentColor.target.value.split(",");

    updateImageById(id);
    setColor({ id: id, color: c });
    sizeSelectByColorId(id);
  };

  const changeImgHandler1 = (current) => {
    setSrc(process.env.REACT_APP_IMAGE_FOLDER + `/` + current);
  };

  const updateImageById = (id) => {
    // Flatten all image objects from product.image
    const allImages = product.image.flatMap(([_, images]) => images);

    // Find the image by id
    const selectedImage = allImages.find((img) => img.p_c_id === id.toString());

    if (selectedImage) {
      setSrc(`${process.env.REACT_APP_IMAGE_FOLDER}/${selectedImage.name}`);
    } else {
      console.error("Image with the specified ID not found");
    }
  };

  const areAllSizesOutOfStock = (data) => {
    return data.every(([_, variants]) =>
      variants.every((variant) => parseFloat(variant.a_qty) <= 0)
    );
  };

  const sizeSelectByColorId = (current) => {
    // Helper function to determine if a special price is active
    const getPriceDetails = (item) => {
      const currentDate = new Date();
      const sPriceStart = item.s_price ? new Date(item.s_price_start) : null;
      const sPriceEnd = item.s_price ? new Date(item.s_price_end) : null;

      const hasSpecialPrice =
        item.s_price !== null &&
        sPriceStart !== null &&
        sPriceEnd !== null &&
        currentDate >= sPriceStart &&
        currentDate <= sPriceEnd;

      return hasSpecialPrice ? item.s_price : item.price;
    };

    // Early default setup
    let matchFound = false;
    let maxAvaQty = 0;
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    let minOldPrice = Infinity;
    let maxOldPrice = -Infinity;

    // Set SKU for the selected color
    const color = product.size.find((value) => value[0] === current);
    if (!color) {
      console.error("Color not found");
      return;
    } else {
      setSelectedSKU(color[1][0].sku);
    }

    // Process sizes
    const sizeOptions = Object.values(product.size).reduce((options, value) => {
      if (current === value[0]) {
        const sizeOptionsForColor = value[1].map((v) => {
          const priceToSet = getPriceDetails(v);

          // Update min/max prices
          minPrice = Math.min(minPrice, priceToSet);
          maxPrice = Math.max(maxPrice, priceToSet);

          // Update old price
          const oldPriceToSet = v.price;
          minOldPrice = Math.min(minOldPrice, oldPriceToSet);
          maxOldPrice = Math.max(maxOldPrice, oldPriceToSet);

          // Update available quantity
          const availableQty = parseInt(v.a_qty, 10);
          if (availableQty > maxAvaQty) {
            maxAvaQty = availableQty;
            setAvaQty(availableQty);
            setNewAvailQty(availableQty);
          }

          // Return size option
          if (v.size !== null) {
            setSizes(true); // Indicate sizes are available
            setVariantType(v.variant_name);
            const priceOfVariant = getPriceDetails(v);
            const oldPriceOfVariant = v.price;

            return (
              <option
                key={v.id}
                className="my-1"
                value={`${v.id},${v.sku},${v.size},${v.a_qty},${priceOfVariant},${oldPriceOfVariant}`}
              >
                {v.size}
              </option>
            );
          }
          return null;
        });

        options.push(...sizeOptionsForColor);
        matchFound = true;
      }
      return options;
    }, []);

    // Update state based on whether a match was found
    if (matchFound) {
      setSizeOptions(sizeOptions);

      // Set price range if applicable
      if (minPrice !== Infinity && maxPrice !== -Infinity) {
        setPrice(
          minPrice === maxPrice
            ? formatter.format(minPrice)
            : `${formatter.format(minPrice)} - ${formatter.format(maxPrice)}`
        );
        setPriceInternal(minPrice);
        setOldPrice(
          minOldPrice === maxOldPrice
            ? formatter.format(minOldPrice)
            : `${formatter.format(minOldPrice)} - ${formatter.format(maxOldPrice)}
          `
        );
      }
    } else {
      // Set default price range
      const { maxp, minp } = product.priceR;
      setPrice(
        maxp === minp
          ? formatter.format(maxp)
          : `${formatter.format(minp)} - ${formatter.format(maxp)}`
      );

      // Reset state for unavailable sizes
      setAvaQty(0);
      setNewAvailQty(100000);
      setSizeOptions([]);
    }
  };

  const changeSizeHandler = (value) => {
    const [id, sku, size, a_qty, price, oldPrice] = value.split(",");
    if (parseInt(a_qty) <= 0) {
      setProductOutOfStock(true);
    } else {
      setProductOutOfStock(false);
    }
    setAvaQty(parseInt(a_qty));
    setNewAvailQty(parseInt(a_qty));
    setSelectedSKU(sku);
    setSize({ id: id, size: size });
    setPriceInternal(price);
    setPrice(formatter.format(price));
    setOldPrice(formatter.format(oldPrice));
  };

  const [quantity, setQuantity] = useState(1);

  const increment = () => {
    setQuantity((prevQuantity) =>
      prevQuantity < avaQty ? prevQuantity + 1 : prevQuantity
    );
  };

  const decrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > 0 && value <= avaQty) {
      setQuantity(value);
    }
  };

  const [province, dropDownProvince] = React.useState("Select Your Province");
  const handleChangeProvince = (event) => {
    dropDownProvince(event.target.province);
  };

  const addToCart = (id) => {
    if (color.id === 0) {
      toast.error("Select the Color");
      return false;
    }
    if (sizes === true && size.id === 0) {
      toast.error(`Select the ${variantType}`);
      return false;
    } else {
      const cart_list = localStorage.getItem("cart_list");
      const item = [
        [id + "_" + color.id + "_" + size.id],
        [quantity],
        [
          [
            product.product,
            color.color,
            size.size,
            parseFloat(priceInternal),
            selectedSKU,
            avaQty,
          ],
        ],
        [parseFloat(priceInternal)],
      ];
      if (cart_list === null) {
        localStorage.setItem("cart_list", JSON.stringify(item));
      } else {
        const cart_list_obj = JSON.parse(cart_list);
        if (cart_list_obj[0].includes(id + "_" + color.id + "_" + size.id)) {
          const index = cart_list_obj[0].indexOf(
            id + "_" + color.id + "_" + size.id
          );
          cart_list_obj[1][index] = cart_list_obj[1][index] + quantity;
          cart_list_obj[3][index] =
            cart_list_obj[3][index] + parseFloat(priceInternal);
          localStorage.setItem("cart_list", JSON.stringify(cart_list_obj));
        } else {
          cart_list_obj[0].push(id + "_" + color.id + "_" + size.id);
          cart_list_obj[1].push(quantity);
          cart_list_obj[2].push([
            product.product,
            color.color,
            size.size,
            priceInternal,
            selectedSKU,
            avaQty,
          ]);
          cart_list_obj[3].push(parseFloat(priceInternal));
          localStorage.setItem("cart_list", JSON.stringify(cart_list_obj));
        }
      }
      setCartCount(JSON.parse(localStorage.getItem("cart_list"))[0].length);
      toast.success("Item Added to Cart");
    }
  };

  const buyNow = (id) => {
    if (color.id === 0) {
      toast.error("Select the Color");
      return false;
    }
    if (sizes === true && size.id === 0) {
      toast.error("Select the Size");
      return false;
    } else {
      localStorage.removeItem("buy_now_list");
      const cart_list = localStorage.getItem("buy_now_list");
      const item = [
        [id + "_" + color.id + "_" + size.id],
        [quantity],
        [
          [
            product.product,
            color.color,
            size.size,
            parseFloat(priceInternal),
            selectedSKU,
          ],
        ],
        [parseFloat(priceInternal)],
      ];
      if (cart_list === null) {
        localStorage.setItem("buy_now_list", JSON.stringify(item));
      } else {
        const cart_list_obj = JSON.parse(cart_list);
        if (cart_list_obj[0].includes(id + "_" + color.id + "_" + size.id)) {
          const index = cart_list_obj[0].indexOf(
            id + "_" + color.id + "_" + size.id
          );
          cart_list_obj[1][index] = cart_list_obj[1][index] + quantity;
          cart_list_obj[3][index] =
            cart_list_obj[3][index] + parseFloat(priceInternal);
          localStorage.setItem("buy_now_list", JSON.stringify(cart_list_obj));
        } else {
          cart_list_obj[0].push(id + "_" + color.id + "_" + size.id);
          cart_list_obj[1].push(quantity);
          cart_list_obj[2].push([
            product.product,
            color.color,
            size.size,
            priceInternal,
            selectedSKU,
          ]);
          cart_list_obj[3].push(parseFloat(priceInternal));
          localStorage.setItem("buy_now_list", JSON.stringify(cart_list_obj));
        }
      }

      window.location.href = "/buy-now";
    }
  };

  const addToWishList = (id) => {
    const wish_list = localStorage.getItem("wish_list");
    const item = [[id], [quantity]];

    if (wish_list === null) {
      localStorage.setItem("wish_list", JSON.stringify(item));
    } else {
      const wish_list_obj = JSON.parse(wish_list);
      if (wish_list_obj[0].includes(id)) {
        const index = wish_list_obj[0].indexOf(id);
        wish_list_obj[1][index] = wish_list_obj[1][index] + quantity;
        localStorage.setItem("wish_list", JSON.stringify(wish_list_obj));
      } else {
        wish_list_obj[0].push(id);
        wish_list_obj[1].push(quantity);
        localStorage.setItem("wish_list", JSON.stringify(wish_list_obj));
      }
    }
  };

  const scrollContainerRef = useRef(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      setShowScrollButtons(container.scrollWidth > container.clientWidth);
    }
  }, [productsImg]); // Assuming productsImg is the array of images

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 200; // Adjust this value as needed for the scroll distance
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 200; // Adjust this value as needed for the scroll distance
    }
  };
  // if ([pro][0].length === undefined)

  return (
    <div
      className={`product-view w-full lg:flex justify-between ${className || ""}`}
    >
      <div
        data-aos="fade-right"
        className="lg:w-2/5 sm:w-full xl:mr-[60px] lg:mr-[30px]"
      >
        <div className="w-full">
          <div className="w-full h-[600px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
            <img src={src} alt="" className="object-contain" />
            {/*<div*/}
            {/*    className="w-[80px] h-[80px] rounded-full theme-main-bg text-qblack flex justify-center items-center text-xl font-medium absolute left-[30px] top-[30px]">*/}
            {/*    <span>-50%</span>*/}
            {/*</div>*/}
          </div>
          <div className="relative">
            <div
              className="flex gap-2 w-full h-[75px] overflow-x-auto scroll-container"
              ref={scrollContainerRef}
            >
              {product.image &&
                [product.image][0].length > 0 &&
                product.image.map((img) =>
                  img[1].map((imgName) => (
                    <div
                      onClick={() => changeImgHandler1(imgName.name)}
                      key={imgName.id}
                      className="min-w-[81px] h-[70px] p-[15px] border border-qgray-border cursor-pointer"
                    >
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_FOLDER +
                          "/" +
                          imgName.name
                        }
                        alt=""
                        className={`w-full h-full object-contain ${
                          src !== img.src ? "opacity-50" : ""
                        } `}
                      />
                    </div>
                  ))
                )}
            </div>
            {showScrollButtons && productsImg.length > 5 && (
              <>
                <button
                  onClick={scrollLeft}
                  className="absolute top-6 left-[-16px] color text-gray-400"
                >
                  &lt;
                </button>
                <button
                  onClick={scrollRight}
                  className="absolute top-6 right-[-16px] color text-gray-400"
                >
                  &gt;
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex lg:w-2/5 sm:w-2/3 border-r">
        <div className="product-details w-full mt-10 lg:mt-0 mr-[15px]">
          <span
            data-aos="fade-up"
            className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
          >
            {product.product.product_sub_cat_name}
          </span>
          <p
            data-aos="fade-up"
            className="text-xl font-medium text-qblack mb-4"
          >
            {product.product.p_name}
          </p>

          {/* <div
            data-aos="fade-up"
            className="flex space-x-[10px] items-center mb-6"
          >
            <div className="flex">
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <span className="text-[13px] font-normal text-qblack">
              6 Reviews
            </span>
          </div> */}
          <div data-aos="fade-up" className="flex space-x-2 items-center mb-7">
            {/*<span className="text-sm font-500 text-qgray line-through mt-2">*/}
            {/*  $9.99*/}
            {/*</span>*/}
            <span className="text-2xl font-500 text-qred">{price}</span>
            {hasSpecialPrice && (
              <span className="text-xl font-400 text-gray-500 line-through ml-2">
                {oldPrice}
              </span>
            )}
            {hasSpecialPrice && (
              <span className="text-sm font-400 text-green-500 ml-2 mb-4">
                {discountPercent}% off
              </span>
            )}
          </div>

          {/* <p
            data-aos="fade-up"
            className="text-qgray text-sm text-normal mb-[30px] leading-7"
          >
            It is a long established fact that a reader will be distracted by
            the readable there content of a page when looking at its layout.
          </p> */}

          <div data-aos="fade-up" className="colors mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              COLOR
            </span>
            <div className="flex space-x-4 items-center">
              <select
                id="colour"
                className="border border-qgray-border overflow-hidden w-full text-qgray bg-white  focus:outline-none justify-between items-center"
                onChange={changeImgHandler}
              >
                <option className="my-1" value={""}>
                  select the colour
                </option>
                {Object.values(product.color).map((value, index) => (
                  <option
                    key={index}
                    className="my-1"
                    value={[value.id, value.name]}
                  >
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {sizes ? (
            <div className="product-size mb-[30px]">
              <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                {variantType}
              </span>
              <div className="w-full">
                <select
                  id="city"
                  className="border border-qgray-border overflow-hidden w-full text-qgray bg-white focus:outline-none justify-between items-center"
                  onChange={(e) => changeSizeHandler(e.target.value)}
                >
                  <option className="my-1" value={""}>
                    Select the Size
                  </option>
                  {sizeOptions} {/* Render the options from state */}
                </select>
              </div>
            </div>
          ) : null}

          <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
            QUANTITY{" "}
            {newAvailQty <= 5 && newAvailQty > 0 ? (
              <span className="text-red-500 text-sm mb-4">
                Only {newAvailQty} Left
              </span>
            ) : (
              ""
            )}
          </span>
          <div className="quantity-card-wrapper w-full flex items-center h-[50px] space-x-[10px] mb-[30px]">
            <div className="w-[120px] h-full px-[26px] flex items-center border border-qgray-border">
              <div className="flex justify-between items-center w-full">
                <button
                  onClick={decrement}
                  type="button"
                  className="text-base text-qgray"
                >
                  -
                </button>
                <input
                  type="number"
                  className="text-qblack text-center w-[40px] ml-[10px]"
                  value={quantity}
                  onChange={handleChange}
                  min="1"
                  max={avaQty}
                />
                <button
                  onClick={increment}
                  type="button"
                  className="text-base text-qgray"
                >
                  +
                </button>
              </div>
            </div>
            {/* <div className="w-[60px] h-full flex justify-center items-center border border-qgray-border">
              <button type="button" onClick={() => addToWishList(1)}>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                      stroke="#D5D5D5"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </span>
              </button>
            </div> */}
            <div className="flex h-full">
              <button
                disabled={productOutOfStock}
                type="button"
                className="blue-btn text-sm font-semibold w-full h-full px-3 mr-4"
                onClick={() => buyNow(product.product.id)}
              >
                Buy Now
              </button>

              <button
                disabled={productOutOfStock}
                type="button"
                className="black-btn text-sm font-semibold w-full h-full px-3"
                onClick={() => addToCart(product.product.id)}
              >
                Add To Cart
              </button>
            </div>
          </div>

          {productOutOfStock ? (
            <p className="text-red-500 text-sm mb-4">Out of Stock</p>
          ) : null}

          <div data-aos="fade-up" className="mb-[20px]">
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Category:</span>{" "}
              {product.product.product_cat_name}
            </p>
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Subcategory:</span>{" "}
              {product.product.product_sub_cat_name}
            </p>
            <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Brand:</span>{" "}
              {product.product.p_brand}
            </p>
          </div>
          {/* <div
            data-aos="fade-up"
            className="flex space-x-2 items-center mb-[20px]"
          >
            <span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0C0.247634 0 0.475436 0 0.729172 0C0.738324 0.160174 0.747477 0.316279 0.757647 0.493233C1.05816 0.392044 1.33885 0.282211 1.62818 0.203395C3.11296 -0.201361 4.51385 0.0366111 5.84202 0.779512C6.47661 1.13494 7.14171 1.39071 7.86987 1.47207C8.88125 1.58496 9.82093 1.35817 10.7098 0.88426C10.9335 0.765274 11.1522 0.636627 11.411 0.491199C11.4161 0.606117 11.4237 0.693577 11.4237 0.780529C11.4242 3.18822 11.4222 5.5954 11.4288 8.00309C11.4293 8.1892 11.3718 8.29089 11.2096 8.38039C9.31956 9.42279 7.4285 9.43499 5.54557 8.37734C4.06231 7.54443 2.55363 7.43307 0.992568 8.13835C0.804428 8.22327 0.737816 8.33005 0.739341 8.53904C0.749003 9.9206 0.744426 11.3027 0.744426 12.6842C0.744426 12.7849 0.744426 12.8851 0.744426 13C0.48764 13 0.254244 13 0 13C0 8.67582 0 4.34961 0 0Z"
                  fill="#EB5757"
                />
              </svg>
            </span>

            <button
              type="button"
              onClick={reportHandler}
              className="text-qred font-semibold text-[13px]"
            >
              Report This Item
            </button>
          </div> */}

          {/* <div
            data-aos="fade-up"
            className="social-share flex  items-center w-full"
          >
            <span className="text-qblack text-[13px] mr-[17px] inline-block">
              Share This
            </span>

            <div className="flex space-x-5 items-center">
              <span>
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 16V9H0V6H3V4C3 1.3 4.7 0 7.1 0C8.3 0 9.2 0.1 9.5 0.1V2.9H7.8C6.5 2.9 6.2 3.5 6.2 4.4V6H10L9 9H6.3V16H3Z"
                    fill="#3E75B2"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 11.4 2.1 14.3 5.1 15.4C5 14.8 5 13.8 5.1 13.1C5.2 12.5 6 9.1 6 9.1C6 9.1 5.8 8.7 5.8 8C5.8 6.9 6.5 6 7.3 6C8 6 8.3 6.5 8.3 7.1C8.3 7.8 7.9 8.8 7.6 9.8C7.4 10.6 8 11.2 8.8 11.2C10.2 11.2 11.3 9.7 11.3 7.5C11.3 5.6 9.9 4.2 8 4.2C5.7 4.2 4.4 5.9 4.4 7.7C4.4 8.4 4.7 9.1 5 9.5C5 9.7 5 9.8 5 9.9C4.9 10.2 4.8 10.7 4.8 10.8C4.8 10.9 4.7 11 4.5 10.9C3.5 10.4 2.9 9 2.9 7.8C2.9 5.3 4.7 3 8.2 3C11 3 13.1 5 13.1 7.6C13.1 10.4 11.4 12.6 8.9 12.6C8.1 12.6 7.3 12.2 7.1 11.7C7.1 11.7 6.7 13.2 6.6 13.6C6.4 14.3 5.9 15.2 5.6 15.7C6.4 15.9 7.2 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z"
                    fill="#E12828"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0722 1.60052C16.432 1.88505 15.7562 2.06289 15.0448 2.16959C15.7562 1.74278 16.3253 1.06701 16.5742 0.248969C15.8985 0.640206 15.1515 0.924742 14.3335 1.10258C13.6933 0.426804 12.7686 0 11.7727 0C9.85206 0 8.28711 1.56495 8.28711 3.48557C8.28711 3.7701 8.32268 4.01907 8.39382 4.26804C5.51289 4.12577 2.9165 2.73866 1.17371 0.604639C0.889175 1.13814 0.71134 1.70722 0.71134 2.34742C0.71134 3.5567 1.31598 4.62371 2.27629 5.26392C1.70722 5.22835 1.17371 5.08608 0.675773 4.83711V4.87268C0.675773 6.5799 1.88505 8.00258 3.48557 8.32268C3.20103 8.39382 2.88093 8.42938 2.56082 8.42938C2.34742 8.42938 2.09845 8.39382 1.88505 8.35825C2.34742 9.74536 3.62784 10.7768 5.15722 10.7768C3.94794 11.7015 2.45412 12.2706 0.818041 12.2706C0.533505 12.2706 0.248969 12.2706 0 12.2351C1.56495 13.2309 3.37887 13.8 5.37062 13.8C11.8082 13.8 15.3294 8.46495 15.3294 3.84124C15.3294 3.69897 15.3294 3.52113 15.3294 3.37887C16.0052 2.9165 16.6098 2.31186 17.0722 1.60052Z"
                    fill="#3FD1FF"
                  />
                </svg>
              </span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="lg:w-1/5 sm:w-1/3 ml-[15px]">
        <div className="product-details mt-10 lg:mt-0">
          <span
            data-aos="fade-up"
            className="text-qgray text-xs font-normal uppercase tracking-wider mb-4 inline-block"
          >
            Delivery
          </span>
          {/* <div className="w-full">
            <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2">
              Select Province *
            </h6>
            <select
              value={province}
              onChange={handleChangeProvince}
              className="card w-full h-[34px] border-b border-[#EDEDED] text-qgray text-[14px] bg-white flex justify-between items-center mb-8"
            >
              <option className="my-1" value="1" selected>
                Western
              </option>
              <option className="mb-1" value="2">
                Central
              </option>
              <option className="mb-1" value="3">
                Sabaragamuwa
              </option>
              <option className="mb-1" value="4">
                Uva
              </option>
              <option className="mb-1" value="5">
                Southern
              </option>
              <option className="mb-1" value="6">
                Northern
              </option>
              <option className="mb-1" value="7">
                North Central
              </option>
              <option className="mb-1" value="8">
                North Western
              </option>
              <option className="mb-1" value="9">
                Eastern
              </option>
            </select>
          </div> */}
          <p className="text-[13px] text-qblack mt-2">
            <strong>Free Delivery</strong> for orders over Rs 2500
          </p>
          <span className="text-[12px] text-[#3E75B2] mt-1">
            {new Date(
              new Date().setDate(new Date().getDate() + 2)
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })}{" "}
            -{" "}
            {new Date(
              new Date().setDate(new Date().getDate() + 4)
            ).toLocaleDateString("en-US", { month: "short", day: "numeric" })}
            , 2 - 4 Days
          </span>
          <p className="border-t border-[#EDEDED] my-3"></p>
          <p className="text-[13px] text-qblack mb-3">
            Cash on Delivery Available
          </p>
        </div>
        <div className="border-t border-[#3FD1FF] my-8"></div>
        <div className="product-details mt-15  lg:mt-0">
          <span
            data-aos="fade-up"
            className="text-qgray text-xs font-normal uppercase tracking-wider mb-4 inline-block"
          >
            Service
          </span>
          <p className="text-[13px] text-qblack mt-2">
            <strong>100%</strong> Trusted Brand
          </p>
          <span className="text-[12px] text-[#3E75B2] mt-1">
            Or get your money back
          </span>
          <p className="border-t border-[#EDEDED] my-3"></p>
          <p className="text-[13px] text-qblack mt-2">
            <strong>7 Days</strong> Hassel free return policy
          </p>
          <span className="text-[12px] text-[#3E75B2] mt-1">
            If you changed your mind
          </span>
          {/* <p className="border-t border-[#EDEDED] my-3"></p> */}
          {/* <p className="text-[13px] text-qblack mt-2">
            <strong>1 Year</strong> Company Warranty
          </p> */}
        </div>
      </div>
    </div>
  );
}
